import React, { useState, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { init } from '@amplitude/analytics-browser';
import { AuthContext } from './context/AuthContext';
import { routes } from './common/routes';
import PageLoader from './components/molecules/page-loader';
import { setAmplitudeUserInfo } from './utils/amplitude';

const RECAPTCHA_SITE_KEY = '6LfBiGMqAAAAAOTSHt1hroF3WcCV020Xd05WWQdp';

function App() {
  const [guestUser, setGuestUser] = useState(null);
  useEffect(() => {
    init('a122cfc4aa87dcbe6bc6b643b0f56348');
    if (guestUser?.email) {
      setAmplitudeUserInfo(guestUser.email);
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <AuthContext.Provider value={{ guestUser, setGuestUser }}>
        <BrowserRouter>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              {Object.values(routes).map(route => (
                <Route key={route.key} path={route.path} element={route.component} />
              ))}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
