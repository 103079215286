import { Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import client from '../services/main-client';

export const API_BASE_URL = 'https://api.joinubqt.com/';

export const trackAmplitude = (
  pageName = '',
  action = 'Open',
  screenType = 'Page',
  additionalProperties = {}
) => {
  if (client().getBaseURL() === API_BASE_URL) {
    const eventProperties = {
      screenType,
      ...additionalProperties,
    };
    track(`${pageName} > ${action}`, eventProperties);
  }
};
export const setAmplitudeUserInfo = user => {
  client().getBaseURL() === API_BASE_URL && setUserId(`${user}`);
};
export const setAmplitudeUserProperties = properties => {
  if (client().getBaseURL() === API_BASE_URL && properties && typeof properties === 'object') {
    const event = new Identify();
    Object.keys(properties).forEach(key => {
      event.set(key, properties[key]);
    });
    identify(event);
  }
};
