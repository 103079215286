import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';
import TripHeader from '../../molecules/trip-header';
import { Button } from '../../atoms';
import OtpInputComponent from '../../molecules/otp-input-component';
import PageLoader from '../../molecules/page-loader';
import useAuth from '../../../utils/hooks/useAuth';
import useApiCall from '../../../utils/hooks/useApiCall';
import { resendOtp, verifyOtp } from '../../../services/settings';
import { Typography } from '../../../styles';
import { joinCatchUpApi } from '../../../services/catch-up';
import { setAmplitudeUserInfo } from '../../../utils/amplitude';

const VerificationCatchUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.main_color};

  .verify_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }

  .form_section {
    background-color: ${colors.white};
    padding: 40px;
    margin-bottom: 15px;
  }

  .text {
    color: ${colors.greyText};
    font-size: 16px;
    text-align: center;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }

  .button_section {
    text-align: center;
    margin-top: 25px;
  }

  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }

  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }

  .verification {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 700;
    line-height: 18px;
  }

  .email_text {
    text-align: center;
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
    color: ${colors.black};
  }

  .link_section {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 20px;
    color: ${colors.main_color};
  }

  .code_text {
    margin-right: 5px;
    font-size: 16px;
    color: ${colors.light_grey};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }

  .link_text {
    color: ${colors.main_color};
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }

  @media screen and (min-width: 768px) {
    .verify_wrapper {
      margin: 25px;
      width: auto;
    }

    .card_wrapper {
      padding: 20px 50px;
    }

    .form_section {
      padding: 20px 80px;
    }
  }
`;

const VerificationCatchUp = () => {
  const params = useParams();
  const { guestUser } = useAuth();
  const navigation = useNavigate();
  const [otp, setOtp] = useState(null);
  const [searchParams] = useSearchParams();
  const avatar = searchParams.get('avatar');
  const slug = searchParams.get('userSlug');
  const time = searchParams.get('token');
  const { t } = useTranslation();
  const location = useLocation();
  const { meeting, user } = location.state || {};

  const handleOtpChange = value => {
    if (value.length <= 4) {
      setOtp(value);
    }
  };

  useEffect(() => {
    if (otp?.length === 4) {
      saveVerifyOtp();
    }
  }, [otp]);

  const [, verifyOtpLoading, verifyOtpCall] = useApiCall(verifyOtp);
  const [, joinCatchUpLoading, joinCatchUpCall] = useApiCall(joinCatchUpApi);
  const [, resendOtpLoading, resendOtpCall] = useApiCall(resendOtp);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyOtpCallWrapper = (obj, callback, errorCallback) => {
    verifyOtpCall(
      obj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const joinCatchUpCallWrapper = (joinCatchUpObj, callback, errorCallback) => {
    joinCatchUpCall(
      joinCatchUpObj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const saveVerifyOtp = () => {
    const joinCatchUpObj = {
      guestEmail: guestUser?.email,
      meetingId: params.name,
    };
    const obj = {
      email: guestUser?.email,
      otp,
    };

    verifyOtpCallWrapper(
      obj,
      () => {
        notification.success({ message: 'OTP verified' });
        setAmplitudeUserInfo(guestUser?.email);

        joinCatchUpCallWrapper(
          joinCatchUpObj,
          () => {
            notification.success({ message: 'Joined catchup successfully' });
            navigation(
              `${`/joined-catch-up`}/${
                params.name
              }?token=${time}&userSlug=${slug}&avatar=${avatar}`,
              { state: { meeting, user } }
            );
          },
          error => {
            notification.error({ message: error.message });
          }
        );
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const resendOtpHandler = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('verification_catchup');

    resendOtpCall(
      {
        email: guestUser?.email,
        recaptchaToken: token,
      },
      () => {
        notification.success({ message: 'OTP sent successfully' });
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const onBackAndClose = () => {
    navigation(`/catch-up-detail/${params.name}?token=${time}&userSlug=${slug}`);
  };

  if (verifyOtpLoading || joinCatchUpLoading || resendOtpLoading) {
    return <PageLoader />;
  }

  return (
    <VerificationCatchUpWrapper>
      <div className='verify_wrapper'>
        <TripHeader onClose={onBackAndClose} />

        <div className='form_section'>
          <div className='verification'>{t('app.codeSent')}</div>
          <div className='text'>{t('app.codeSentMessage')}</div>
          <div className='email_text'>{guestUser?.email}</div>
          <OtpInputComponent length={4} onChange={handleOtpChange} />

          <div className='button_section'>
            <Button className='button' onClick={() => saveVerifyOtp()}>
              {t('app.continue')}
            </Button>
          </div>
          <div className='link_section'>
            <div className='code_text'>{t('app.noCode')}</div>
            <div className='link_text' onClick={resendOtpHandler}>
              {t('app.resendCode')}
            </div>
          </div>
        </div>
      </div>
    </VerificationCatchUpWrapper>
  );
};

export default VerificationCatchUp;
