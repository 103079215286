import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../../configs/env-vars';
import Home from './home';
import useApiCall from '../../utils/hooks/useApiCall';
import { fetchAllSettingsApi } from '../../services/settings';
import PageLoader from '../molecules/page-loader';
import { setAmplitudeUserInfo, trackAmplitude } from '../../utils/amplitude';

const Stepper = () => {
  const [settings, settingsLoading, settingsCall] = useApiCall(fetchAllSettingsApi);

  useEffect(() => {
    trackAmplitude('Signup', 'Open', 'WebPage');
    settingsCall();
  }, []);

  if (settingsLoading) {
    return <PageLoader />;
  }

  return (
    settings && (
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Home settings={settings} />
      </GoogleOAuthProvider>
    )
  );
};

export default Stepper;
